<bit-dialog dialogSize="large">
  <span bitDialogTitle>
    {{ cipherTypeString }}
  </span>
  <ng-container bitDialogContent>
    <app-cipher-view [cipher]="cipher"></app-cipher-view>
  </ng-container>
  <ng-container bitDialogFooter>
    <button bitButton (click)="edit()" buttonType="primary" type="button" [disabled]="!cipher.edit">
      {{ "edit" | i18n }}
    </button>
    <div class="ml-auto">
      <button
        bitButton
        type="button"
        buttonType="danger"
        [appA11yTitle]="'delete' | i18n"
        [bitAction]="delete"
        [disabled]="!cipher.edit"
      >
        <i class="bwi bwi-trash bwi-lg bwi-fw" aria-hidden="true"></i>
      </button>
    </div>
  </ng-container>
</bit-dialog>
