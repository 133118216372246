<main
  class="tw-flex tw-min-h-screen tw-w-full tw-mx-auto tw-flex-col tw-gap-7 tw-bg-background-alt tw-px-8 tw-pb-4 tw-text-main"
  [ngClass]="{ 'tw-pt-0': decreaseTopPadding, 'tw-pt-8': !decreaseTopPadding }"
>
  <bit-icon *ngIf="!hideLogo" [icon]="logo" class="tw-w-[128px] [&>*]:tw-align-top"></bit-icon>

  <div class="tw-text-center">
    <div class="tw-mx-auto tw-max-w-28 sm:tw-max-w-32">
      <bit-icon [icon]="icon"></bit-icon>
    </div>

    <h1 *ngIf="title" bitTypography="h3" class="tw-mt-2 sm:tw-text-2xl">
      {{ title }}
    </h1>
    <div *ngIf="subtitle" class="tw-text-sm sm:tw-text-base">{{ subtitle }}</div>
  </div>

  <div
    class="tw-mb-auto tw-w-full tw-max-w-md tw-mx-auto tw-flex tw-flex-col tw-items-center sm:tw-min-w-[28rem]"
    [ngClass]="{ 'tw-max-w-md': maxWidth === 'md', 'tw-max-w-3xl': maxWidth === '3xl' }"
  >
    <div
      class="tw-rounded-2xl tw-mb-9 tw-mx-auto tw-w-full sm:tw-bg-background sm:tw-border sm:tw-border-solid sm:tw-border-secondary-300 sm:tw-p-8"
    >
      <ng-content></ng-content>
    </div>
    <ng-content select="[slot=secondary]"></ng-content>
  </div>

  <footer *ngIf="!hideFooter" class="tw-text-center">
    <div *ngIf="showReadonlyHostname">{{ "accessing" | i18n }} {{ hostname }}</div>
    <ng-container *ngIf="!showReadonlyHostname">
      <ng-content select="[slot=environment-selector]"></ng-content>
    </ng-container>
    <ng-container *ngIf="!hideYearAndVersion">
      <div>&copy; {{ year }} Bitwarden Inc.</div>
      <div>{{ version }}</div>
    </ng-container>
  </footer>
</main>
